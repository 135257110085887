import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FormGroup from './Form/FormGroup';
import InputText from './Form/InputText';
import InputLabel from './Form/InputLabel';
import Checkbox from './Form/Checkbox';
import Radio from './Form/Radio';
import InputMask from 'react-input-mask';
import api from '../Api';
import Select from './Form/Select';
import { browserName, isMobile, browserVersion, mobileVendor, osName, osVersion } from 'react-device-detect';

import States from '../Assets/Json/States.json';
import Cities from '../Assets/Json/Cities.json';

function RegisterForm({ selective }) {

	const [state, setState] = useState('');
	const [ip, setIp] = useState('');
	const [city, setCity] = useState(Cities);
	const [cities, setCities] = useState([]);
	const [error, setError] = useState(false);
	const [subscribeStatus, setSubscribeStatus] = useState('empty');
	
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		mobile_phone: '',
		birthdate: '',
		state_id: '',
		city_id: '',
		gender_id: 1,
		browser: browserName,
		browser_version: browserVersion,
		platform: osName,
		plaftorm_version: osVersion,
		ip: ip,
		referrer: document.referrer,
		mobile: isMobile ? mobileVendor : null,
		site_id: process.env.REACT_APP_SITE_ID,
		api_token: process.env.REACT_APP_API_TOKEN,
		selective_id: selective.id,
	});

	const getIp = async () => {
		try {
			const res = await axios.get("https://api.ipify.org/?format=json");
			setIp(res.data.ip);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getIp();
	}, []);

	const states = States;
	const selectedState = 26;
	const selectedCity = 9668;

	if (error === true) {
		return (
			<div className="flex lg:h-full items-center gap-2 flex-col justify-center p-4 lg:p-8 text-center">
				<h3 className="text-xl text-orange-700 font-bold">Faça Seu Cadastro Agora via WhatsApp!</h3>
				<p className="text-md text-gray-900">Entre em contato com nossa equipe clicando no botão abaixo, lá você também poderá tirar todas as informações necessárias da Seletiva e fazer seu cadastro na hora!</p>
				<a href={`https://wa.me/55${selective.mobile_phone ? selective.mobile_phone.replace(/\D/g, ''): '11916427454'}`} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Fale conosco agora</a>
			</div>
		);
	}

	const handleChange = (event) => {
		setFormData({
			...formData,
			[event.target.name]: event.target.value,
		});
	};

	const handleStateChange = (event) => {
		const selectedState = event.target.value;
		const citiesByState = citiesMap(selectedState);
		setState(selectedState);
		setCities(citiesByState);
		handleChange(event);
	}

	const citiesMap = (state = null) => {
		if (state)
		{
			return Cities[state] || [];
		}
		
		return {0: 'Selecione um estado.'};
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		setSubscribeStatus('loading');

		axios
			.post(process.env.REACT_APP_API_URL + '/leads/register', formData, {
				headers: {
					'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
			})
			.then((response) => {
				let statusCode = response.status;

				if (statusCode === 200) {
					if (response.data.type && response.data.type === 'same_mail_and_name') {
						setSubscribeStatus('sameEmailAndName');
					} else if (response.data.status === 'success') {
						setSubscribeStatus('success');
					} else {
						setSubscribeStatus('error');
					}
				} else {
					setSubscribeStatus('error');
				}
				setSubscribeStatus('success');
			})
		.catch((error) => {
			console.log(error);
			if (process.env.REACT_APP_ENV === 'local') {
				console.log(formData);
			}

			setSubscribeStatus('error');
		});
	};

	return (
		<form onSubmit={handleSubmit} className="relative p-8">
			{subscribeStatus === 'loading' &&
				<div
					className="absolute top-0 left-0 w-full h-full flex items-center gap-2 flex-col justify-center p-8 bg-white z-20 text-2xl text-blue-500 text-center">
					<h3>Por favor aguarde, estamos enviando sua inscrição.</h3>
				</div>
			}
			{subscribeStatus === 'success' &&
				<div
					className="absolute top-0 left-0 w-full h-full flex items-center gap-2 flex-col justify-center p-8 bg-white z-20 text-2xl text-orange-500 text-center">
					<h3 className="text-xl lg:text-2xl text-black font-bold uppercase">Obrigado!</h3>
					<p className="text-md md:text-lg text-gray-800">Seu cadastro foi realizado com sucesso! Entraremos
						em contato com você em breve.</p>
				</div>
			}
			{subscribeStatus === 'error' &&
				<div
					className="absolute top-0 left-0 w-full h-full flex items-center gap-2 flex-col justify-center p-8 bg-white z-20 text-2xl text-red-500 text-center">
					<h3>Ops! Houve um erro ao realizar seu cadastro. Clique no botão abaixo e faça seu cadastro
						diretamente conosco!</h3>
					<a href={`https://wa.me/55${selective.mobile_phone ? selective.mobile_phone.replace(/\D/g, '') : '11916427454'}`}
					   className="bg-red-500 rounded hover:bg-red-700 text-white py-2 shadow-md px-4">Cadastrar
						Agora</a>
				</div>
			}
			{subscribeStatus === 'sameEmailAndName' &&
				<div
					className="absolute top-0 left-0 w-full h-full flex items-center gap-2 flex-col justify-center p-8 bg-white z-20 text-2xl text-red-500 text-center">
					<h3>Já foi feito um cadastro recente com esse e-mail e nomes, por favor tente daqui alguns dias. Seu
						cadastro pode estar em análise e iremos entrar e contato em breve!</h3>
					<a href={`https://wa.me/55${selective.mobile_phone ? selective.mobile_phone.replace(/\D/g, '') : '11916427454'}`}
					   className="bg-red-500 rounded hover:bg-red-700 text-white py-2 shadow-md px-4">Fale Conosco</a>
				</div>
			}

			<input type="hidden" name="site_id" value={process.env.REACT_APP_SITE_ID} />
			<input type="hidden" name="selective_id" value={selective.id} />

			<FormGroup>
				<InputLabel value="Gênero" htmlFor="form_gender_id"/>
				<select
					id="form_gender_id"
					name="gender_id"
					className="w-full border-gray-300 border-2 focus:border-blue-500 focus:text-blue-500 rounded-md shadow-sm text-sm"
					value={formData.gender_id}
					onChange={handleStateChange}
				>
					<option value={20}>Feminino</option>
					<option value={21}>Masculino</option>
					<option value={22}>Não binário</option>
					<option value={23}>Preferir não dizer</option>
					<option value={24}>Outros</option>
				</select>
			</FormGroup>

			<FormGroup>
				<InputLabel value="Nome" htmlFor="form_name"/>
				<InputText name="name" id="form_name" value={formData.name} onChange={handleChange} required/>
			</FormGroup>

			<FormGroup>
				<InputLabel value="E-mail" htmlFor="form_email"/>
				<InputText name="email" type="email" id="form_email" value={formData.email} onChange={handleChange}
						   required/>
			</FormGroup>

			<FormGroup>
				<InputLabel value="Celular" htmlFor="form_mobile_phone"/>
				<InputMask
					mask="(99) 9999-99999"
					className="w-full border-gray-300 border-2 focus:border-blue-500 focus:text-blue-500 rounded-md shadow-sm"
					maskChar=" "
					minLength="14"
					type="tel"
					name="mobile_phone"
					id="form_mobile_phone"
					value={formData.mobile_phone}
					onChange={handleChange}
					required
				/>
			</FormGroup>

			<FormGroup>
				<InputLabel value="Data de Nascimento" htmlFor="form_birthdate"/>
				<InputMask
					mask="99/99/9999"
					className="w-full border-gray-300 border-2 focus:border-blue-500 focus:text-blue-500 rounded-md shadow-sm"
					maskChar=" "
					minLength="10"
					type="text"
					name="birthdate"
					id="form_birthdate"
					value={formData.birthdate}
					onChange={handleChange}
					required
				/>
			</FormGroup>

			<FormGroup>
				<InputLabel value="Estado" htmlFor="form_state"/>
				<select
					id="form_states"
					name="state_id"
					className="w-full border-gray-300 border-2 focus:border-blue-500 focus:text-blue-500 rounded-md shadow-sm text-sm"
					onChange={handleStateChange}
				>
					{states.map((option) => (
						<option key={'state_' + option.value} value={option.value}>{option.name}</option>
					))}
				</select>
			</FormGroup>

			<FormGroup>
				<InputLabel value="Cidade" htmlFor="form_city"/>
				<select
					id="form_cities"
					name="city_id"
					className="w-full border-gray-300 border-2 focus:border-blue-500 focus:text-blue-500 rounded-md shadow-sm text-sm"
					onChange={handleChange}
				>
					{cities.length === 0 && <option value="0">Selecione um estado.</option>}
					{cities.length > 0 &&
						cities.map((option) => (
							<option key={'state_' + option.value} value={option.value}>{option.name}</option>
						))
					}
				</select>
			</FormGroup>

			<FormGroup>
				<button type="submit"
						className="bg-orange-500 hover:bg-orange-700 w-full text-white text-center font-bold py-2 px-4 rounded uppercase mt-4">Cadastrar
				</button>
			</FormGroup>
		</form>
	);
}

export default RegisterForm;