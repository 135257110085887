import axios from 'axios';

const apiConfig = {
    headers: {
        'Authorization': 'Bearer ' + process.env.REACT_APP_API_TOKEN,
    }
}

const Api = {
    getSelectives: () => axios.get(process.env.REACT_APP_API_URL + '/selectives', apiConfig),
    getSelective: (slug) => axios.get(process.env.REACT_APP_API_URL + `/selectives/${slug}`, apiConfig),
    getOptions: () => axios.get(process.env.REACT_APP_API_URL + '/options/all', apiConfig),
}

export default Api;