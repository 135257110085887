import { Link } from "react-router-dom";

function Card(props) {
    return (
        <div className="card w-full mb-8 relative text-left" itemScope itemType="https://schema.org/Event">
            {props.model.featured_image && <img src={props.model.featured_image} alt={props.model.name} className="w-full oject-cover rounded-md shadow-md" />}
            <div className="relative px-4 -mt-16">
                <div className="bg-white p-6 rounded-md shadow-lg">
                    <div className="flex-items-baseline mb-3" itemProp="location" itemScope itemType="https://schema.org/Place">
                        <span className="bg-gray-100 text-teal-800 text-md px-2 py-1 inline-block rounded-md uppercase 
                                         font-semibold tracking-wide" itemProp="addressLocality">
                            {props.model.address.city ?? 'Cidade'}
                        </span>
                        <span className="ml-2 text-gray-600 uppercase text-md font-semibold tracking-wide" itemProp="addressRegion">
                            {props.model.address.state ?? 'Estado'}
                        </span>
                    </div>
                    <h3 className="text-3xl font-semibold leading-tight mb-3" itemProp="name">{props.model.name}</h3>
                    <div className="flex space-x-2 justify-left" itemScope itemType="https://schema.org/Offer">
                        <meta itemProp="price" content="0" />
                        <meta itemProp="priceCurrency" content="BRL" />
                        <link itemProp="availability" href="https://schema.org/InStock" />
                        <Link to={`/selective/${props.model.slug}`} className="inline-block px-6 py-2.5 bg-orange-600 text-white font-semibold 
                            text-md uppercase rounded shadow-md hover:bg-orange-500 hover:shadow-lg focus:bg-orange-700 focus:shadow-lg 
                            focus:outline-none focus:ring-0  active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out" 
                            itemProp="url">
                            Faça Parte!
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Card;