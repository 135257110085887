import { forwardRef, useRef } from 'react';

export default forwardRef(function TextInput({ type = 'text', className = '', isFocused = false, ...props }, ref) {
    const inputRef = useRef(null) || ref;

    return (
        <input
            {...props}
            ref={inputRef}

            type={type}
            className={
                'w-full border-gray-300 border-2 focus:border-blue-500 focus:text-blue-500 rounded-md shadow-sm ' +
                className
            }
        />
    );
});

